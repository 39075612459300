<template>
    <!-- 微信通讯录展示组件 https://open.work.weixin.qq.com/api/doc/90001/90143/91958 -->
    <ww-open-data :type="type" :openid="id" :userName="name"></ww-open-data>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'OpenData',
    props: {
        id: {
            type: [String, Number],
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'userName'
        }
    },
    computed: {
        ...mapState(['bindWWOpData'])
    },
    watch: {
        bindWWOpData(val) {
            // eslint-disable-next-line
            if (val) WWOpenData.bind(this.$el);
        }
    }
};
</script>
