<template>
    <div class="poster-pre" ref="poster">
        <img :src="poster_conf.bg_img || ''" v-show="poster_conf.bg_img" alt="" />
        <div class="preview">
            <p
                v-for="item in poster_conf.elements || []"
                :key="item.id"
                :style="`width:${item.w}px;height:${item.h}px; top:${item.y}px;left:${item.x}px;
                `"
                :class="`${item.id}-box`"
            >
                <template v-if="item.t === 'img' && item.hide === 0">
                    <img :src="`${item.src}`" alt="" />
                </template>
                <template v-if="item.t === 'txt' && item.hide === 0">
                    <span :style="`color:${item.color};fontSize:${item.size}px`">{{ item.ctn }}</span>
                </template>
            </p>
        </div>
    </div>
</template>
<script>
import html2canvas from 'html2canvas';
export default {
    props: {
        poster_conf: {
            type: Object
        }
    },
    methods: {
        printCanvas() {
            return new Promise((resolve) => {
                const ele = this.$refs.poster;
                html2canvas(ele, {
                    width: ele.offsetWidth,
                    height: ele.offsetHeight,
                    useCORS: true,
                    allowTaint: true,
                    scale: 4,
                    dpi: 300,
                    backgroundColor: '#fff'
                }).then((canvas) => {
                    console.log('canvas', canvas);
                    // document.body.appendChild(canvas)

                    // 显示图片
                    // 新增代码 返回图片的URL,设置为png格式。dataUrl是一个base64编码的图片URL地址
                    const dataUrl = canvas.toDataURL('image/png');
                    resolve(dataUrl);
                    // showImg.setAttribute('src', dataUrl);

                    // 自动下载图片
                    // var downloadUrl = dataUrl.replace('image/png', 'image/octet-stream'); //图片地址
                    // console.log(downloadUrl);
                    // window.location.href = downloadUrl; // 跳转下载
                });
            }).catch(() => {
                throw new Error('下载失败，请稍后重试');
            });
        }
    }
};
</script>
<style scoped lang="scss">
.poster-pre {
    // position: absolute;
    // top: 0;
    // left: 0;
    box-shadow: 0 0 21.9px 0 #3333331f;
    background: #fff;
    width: 192px;
    height: 297px;
    transform: scale(1.62);
    border-radius: 8px;
    overflow: hidden;
    z-index: 55;
    img {
        width: 100%;
        height: 297px;
    }
    .preview {
        position: absolute;
        top: 0;
        left: 0;
        // box-shadow: 0 0 21.9px 0 #3333331f;
        width: 100%;
        height: 100%;
        p {
            position: absolute;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .nickname-box {
            line-height: 14px;
        }
        .guidingCopy-box {
            line-height: 16px;
            font-weight: 600;
        }
    }
}
</style>
