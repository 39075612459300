<template>
    <div class="footer-area" :style="themeColor">
        <div class="footer-line">
            <div class="xiaoe-logo">
                <i class="iconfont icon-logo"></i>
            </div>
            <p class="footer-text">小鹅通提供技术支持</p>
        </div>
        <div class="footer-line">
            <p class="footer-text">www.xiaoe-tech.com</p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        themeColor: {
            type: Object,
            default: () => {
                return {
                    color: '#c8c8c8'
                };
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.footer-area {
    margin-top: 0.5rem;
    margin-bottom: 0.6rem;
    .footer-line {
        line-height: 0.36rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.08rem;
    }
    .xiaoe-logo {
        width: 0.28rem;
        height: 0.28rem;
        margin-right: 0.1rem;
    }
    .footer-text {
        font-size: 0.2rem;
        line-height: 0.28rem;
    }
}
</style>
