<template>
    <div class="reminder">
        <div class="bg"></div>
        <div class="header">
            <div class="header_qr_code">
                <img :src="headerContent.qr_code" alt="" />
            </div>
            <div class="header_right">
                <p class="title">{{ headerContent.title }}</p>
                <p class="date">{{ headerContent.created_at }}</p>
            </div>
        </div>
        <div class="list">
            <van-list
                v-if="!isShowEmpty"
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
            >
                <div class="listIn" v-for="item in list" :key="item.user_id" :title="item">
                    <div class="listInI">
                        <div class="avator">
                            <img :src="item.user_url" alt="" />
                            <open-data :name="item.NAME" :id="item.user_id" type="userName"></open-data>
                        </div>
                        <p class="result">
                            异常原因：
                            <span>{{ item.mark }}</span>
                        </p>
                        <p class="result">
                            异常时间：
                            <span>{{ item.created_at }}</span>
                        </p>
                    </div>
                </div>
            </van-list>
            <!-- 暂无数据 -->
            <div v-if="isShowEmpty" class="no-data">
                <img :src="require('../../assets/images/nodata.svg')" alt="" />
                <div>暂无数据</div>
            </div>
        </div>
    </div>
</template>
<script>
import { fetchChannelCodeInfoUrl } from './api/index';
import OpenData from './openData';
export default {
    data() {
        return {
            loading: false,
            finished: false,
            list: [],
            isShowEmpty: false,
            page: 1,
            total: 0,
            headerContent: {
                qr_code: '',
                title: '',
                created_at: ''
            }
        };
    },
    components: {
        OpenData
    },
    created() {
        console.log('测试一下')
        this.initWechatWorkSdk();
        this.initData();
    },
    methods: {
        initWechatWorkSdk() {
            const currentUrl = window.location.href.split('#')[0];
            this.$ajax('get_sdk_agent_info', {
                url: currentUrl
            }).then((res) => {
                if (res.data && res.data.code === 0) {
                    let { agent_config } = res.data.data;
                    wx.agentConfig({
                        corpid: agent_config.corpid, // 必填，企业微信的corpID
                        agentid: agent_config.agentid,
                        timestamp: agent_config.timestamp, // 必填，生成签名的时间戳
                        nonceStr: agent_config.nonceStr, // 必填，生成签名的随机串
                        signature: agent_config.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
                        jsApiList: ['selectExternalContact'],
                        success: function (res) {
                            console.log('异常列表SDK初始化成功了', res);
                        },
                        fail: function (err) {
                            console.log('异常列表SDK初始化失败了', err);
                        }
                    });
                    this.$store.commit('setBindWWOpData', true);
                }
            });
        },
        initData() {
            const { card_id } = this.$route.query;
            const payload = {
                page: this.page,
                page_size: 10,
                card_id: card_id
            };
            fetchChannelCodeInfoUrl(payload)
                .then((res) => {
                    console.log('resss', res.data);
                    const d = res.data;
                    const l = this.list;
                    this.headerContent.title = d.head?.title;
                    this.headerContent.qr_code = d.head?.url;
                    this.headerContent.created_at = d.head?.created_at;
                    this.list = l.concat(d.list);
                    this.toatl = res.data.page.total;
                    if (this.toatl === 0) {
                        this.isShowEmpty = true;
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.loading = false;
                    this.isShowEmpty = true;
                    this.$toast.fail('渠道活码获取失败');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onLoad() {
            if (this.toatl !== this.list.length) {
                this.page += 1;
                this.initData();
            } else {
                this.finished = true;
            }
            // 异步更新数据
            // setTimeout(() => {
            //     for (let i = 0; i < 10; i++) {
            //         this.list.push(this.list.length + 1);
            //     }
            //     // 加载状态结束
            //     this.loading = false;
            //     // 数据全部加载完成
            //     if (this.list.length >= 40) {
            //         this.finished = true;
            //     }
            // }, 500);
        }
    }
};
</script>
<style lang="scss" scoped>
.no-data {
    line-height: 0.48rem;
    // 避免模板数量少，未撑满页面时，滑动tab不切换的问题
    height: calc(100vh - 160px);
    font-size: 0.32rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30%;
    box-sizing: border-box;
    img {
        width: 1.76rem;
        height: 1.76rem;
        margin-bottom: 0.32rem;
    }
}
.reminder {
    background: #f5f5f5;
    padding-top: 136px;
    font-size: 14px;
    color: #333;
    position: relative;
    .bg {
        position: fixed;
        width: 100%;
        height: 126px;
        background: #f5f5f5;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;
    }
    .header {
        position: fixed;
        top: 0;
        left: 16px;
        width: calc(100% - 32px);
        padding: 16px;
        box-sizing: border-box;
        margin: 16px auto;
        background: #fff;
        border-radius: 8px;
        display: flex;
        box-shadow: 0 0 21.9px 0 #3333331f;
        z-index: 4;
        .header_qr_code {
            width: 80px;
            margin-right: 8px;
            img {
                width: 80px;
                height: 80px;
            }
        }
        .header_right {
            // flex: 1;
            width: calc(100% - 88px);
            height: 80px;
            position: relative;
            .title {
                width: 100%;
                font-size: 16px;
                font-weight: Medium;
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
                line-height: 20px;
            }
            .employees {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 12px;
                height: 20px;
                line-height: 20px;
            }
            .date {
                color: #999999;
                margin-top: 4px;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }
    .list {
        width: calc(100% - 32px);
        margin: 8px auto;
        // background: #fff;
        border-radius: 8px;
        overflow: hidden;
        background: #fff;
        .listIn {
            background: #fff;
            padding: 16px 16px 0 16px;
            box-sizing: border-box;
            .listInI {
                box-shadow: inset 0 -1px 0 0 #ebebeb;
                padding-bottom: 16px;
                .avator {
                    color: #333333;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    border-radius: 50%;
                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 4px;
                        border-radius: 50%;
                    }
                }
                .result {
                    color: #999999;
                    font-size: 12px;
                    margin-top: 8px;
                    span {
                        color: #333;
                    }
                }
            }
        }
    }
}
</style>
