import NetWork from '@xiaoe/js-tools';

export const fetchChannelCodeInfoUrl = (params) => {
    return NetWork.ajax({
        url: `/forward_admin/wework/contact_card/get_card_wrong_log`,
        method: 'get',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '渠道活码异常信息获取失败';
        });
};
