import NetWork from '@xiaoe/js-tools';

const CHANNEL_CODE_MIDDLE_PAGE = '/forward_admin/wework_toolbar/contact_card_detail';

export const fetchChannelCodeInfo = (params) => {
    return NetWork.ajax({
        url: CHANNEL_CODE_MIDDLE_PAGE,
        method: 'get',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '渠道活码获取失败';
        });
};

export const fetchChannelCodeInfoUrlLink = (params) => {
    return NetWork.ajax({
        url: `/forward_admin/wework_toolbar/get_page_conf_detail`,
        method: 'get',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '渠道活码获取失败';
        });
};

export const fetchChannelCodeInfoUrl = (params) => {
    return NetWork.ajax({
        url: `/forward_admin/wework_toolbar/get_card_wrong_log`,
        method: 'get',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '渠道活码异常信息获取失败';
        });
};
