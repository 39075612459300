<template>
    <div class="wx_customer">
        <div class="wx_content">
            <div class="add-customer-qr">
                <div class="customer-service-title">{{ renderContext.title }}</div>
                <div class="customer-service-desc">{{ renderContext.des }}</div>
                <div class="customer-service-img-box">
                    <span class="box-frame-top-left"></span>
                    <span class="box-frame-top-right"></span>
                    <span class="box-frame-bottom-left"></span>
                    <span class="box-frame-bottom-right"></span>
                    <img class="customer-service-img" :src="renderContext.qrCode" />
                </div>
                <div class="fingerprint-box">
                    <img
                        class="fingerprint-img"
                        src="https://commonresource-1252524126.cdn.xiaoeknow.com/image/lq69ao5f0cbo.png"
                    />
                    <span class="fingerprint-text">长按屏幕识别添加</span>
                </div>
                <span class="qr-text">二维码已通过安全验证</span>
            </div>
            <div class="wx_qrcode_tips">
                <img src="https://commonresource-1252524126.cdn.xiaoeknow.com/image/lq6303h80we7.png" alt="" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            renderContext: {
                title: '识别添加',
                des: '添加你的专属客服',
                qrCode: ''
            }
        };
    },
    methods: {
        getData() {
            const { rule_id, app_id, external_user_id, expand_field } = this.$route.query;
            if (rule_id) {
                const params = {
                    rule_id,
                    app_id,
                    external_user_id,
                    expand_field
                };
                this.$ajax('get_resource_code_info', params)
                    .then((res) => {
                        this.isLoading = false;
                        if (res.data.code === 0) {
                            // 同步头像和名字为推广设置页所设置的
                            this.renderContext.qrCode = res.data.data.qr_code;
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.log(error);
                    });
            }
        }
    },
    created() {
        this.getData();
    }
};
</script>
<style scoped lang="scss">
.wx_customer {
    width: 100vw;
    height: 100vh;
    background: url('https://commonresource-1252524126.cdn.xiaoeknow.com/image/lq623nh10oei.png') no-repeat center
        center;
    background-size: 100% 100%;
    .wx_content {
        width: 3.15 * 2rem;
        height: 4.63 * 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -66%);
        .add-customer-qr {
            width: 100%;
            height: 3.27 * 2rem;
            text-align: center;
            color: #333333ff;
            font-family: 'PingFang SC';
            background: url('https://commonresource-1252524126.cdn.xiaoeknow.com/image/lr78lo1s0uki.png') no-repeat
                center center;
            background-size: 100% 100%;

            .customer-service-title {
                height: 0.2 * 2rem;
                line-height: 0.2 * 2rem;
                font-size: 0.16 * 2rem;
                font-weight: 500;
                padding-top: 0.25 * 2rem;
            }

            .customer-service-desc {
                margin-top: 0.04 * 2rem;
                height: 0.2 * 2rem;
                line-height: 0.2 * 2rem;
                font-size: 0.14 * 2rem;
                font-weight: 400;
            }

            .customer-service-img-box {
                position: relative;
                margin: 0 auto;
                margin-top: 0.2 * 2rem;
                width: 1.6 * 2rem;
                height: 1.6 * 2rem;

                span {
                    position: absolute;
                    width: 0.2737 * 2rem;
                    height: 0.2421 * 2rem;
                    border: 0.02 * 2rem solid #ff781f;
                }

                .box-frame-top-left {
                    top: 0;
                    left: 0;
                    border-bottom: transparent;
                    border-right: transparent;
                    border-radius: 0.08 * 2rem 0 0 0;
                }
                .box-frame-top-right {
                    top: 0;
                    right: 0;
                    border-bottom: transparent;
                    border-left: transparent;
                    border-radius: 0 0.08 * 2rem 0 0;
                }
                .box-frame-bottom-left {
                    bottom: 0;
                    left: 0;
                    border-top: transparent;
                    border-right: transparent;
                    border-radius: 0 0 0 0.08 * 2rem;
                }
                .box-frame-bottom-right {
                    bottom: 0;
                    right: 0;
                    border-top: transparent;
                    border-left: transparent;
                    border-radius: 0 0 0.08 * 2rem 0;
                }
                .customer-service-img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 1.28 * 2rem;
                    height: 1.28 * 2rem;
                    transform: translate(-50%, -50%);
                }
            }

            .fingerprint-box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 0.22 * 2rem;
                margin-top: 0.2 * 2rem;

                .fingerprint-img {
                    width: 0.16 * 2rem;
                    height: 0.16 * 2rem;
                }

                .fingerprint-text {
                    font-size: 0.12 * 2rem;
                    line-height: 0.22 * 2rem;
                    color: #ff781f;
                }
            }

            .qr-text {
                color: #999999;
                font-size: 0.12 * 2rem;
            }
        }
        .wx_qrcode_tips {
            width: 100%;
            margin-top: 0.16 * 2rem;
            img {
                width: 100%;
            }
        }
    }
}
</style>
