<!--- 渠道活码中间页 -->
<template>
    <div class="channel" v-loading="loading">
        <!-- <div class="channel-conetnt" v-if="qrCode">
            <img :src="qrCode" />
            <span class="channel-conetnt__tip">
                <i class="iconfont icon-zhiwen1 fingerprint"></i>
                长按识别二维码，或保存图片
            </span>
        </div> -->
        <!-- <div class="canvasImage">
            <p><img :src="dataUrl" alt="" /></p>
            <p class="tips">若长按无法识别，可保存二维码使用微信扫一扫</p>
        </div> -->
        <div v-if="isCompleteFlag === 1">
            <div class="wrap">
                <div class="printCanvas">
                    <create-canvas :poster_conf="poster_conf" ref="posterCanvas"></create-canvas>
                </div>
                <p class="tips">若长按无法识别，可保存二维码使用微信扫一扫</p>
            </div>

            <xiaoEFooter class="xiaoE-footer" :themeColor="{ color: '#ccc' }" />
        </div>

        <div class="warp-error" v-if="isCompleteFlag === 2">
            <img src="@/assets/images/xiaoe-empty.png" />
            <p>页面加载异常，请联系客服</p>
        </div>
    </div>
</template>

<script>
import xiaoEFooter from '@/views/staffPromotion/components/footer';
import createCanvas from './ceateCanvas.vue';
import { fetchChannelCodeInfoUrlLink } from './api/index';

export default {
    components: {
        xiaoEFooter,
        createCanvas
    },
    props: {
        isResqueset: {
            type: Boolean,
            default: () => true
        }
    },
    data() {
        return {
            appId: '',
            codeId: '',
            qrCode: '',
            loading: false,
            isCompleteFlag: 1,
            dataUrl: '',
            poster_conf: {}
        };
    },

    created() {
        this.initChannelCode();
    },
    mounted() {
        // this.goodess();
    },
    methods: {
        // goodess() {
        //     this.$refs.posterCanvas.printCanvas().then((res) => {
        //         console.log('ress', res);
        //         this.dataUrl = res;
        //     });
        // },
        initChannelCode() {
            const { code_id, lead_link_id, corp_id } = this.$route.query;
            const payload = {
                card_id: code_id,
                lead_link_id,
                corp_id
            };
            this.loading = true;
            fetchChannelCodeInfoUrlLink(payload)
                .then((res) => {
                    if (res.code === 0) {
                        // const { qr_code } = res.data;
                        // this.qrCode = qr_code;
                        this.poster_conf = res.data;
                        this.isCompleteFlag = 1;
                    } else {
                        this.isCompleteFlag = 2;
                        throw new Error('渠道活码获取失败:');
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.loading = false;
                    this.$toast.fail('渠道活码获取失败');
                    this.isCompleteFlag = 2;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.channel {
    // height: calc(100vh - 100px);
    // padding-top: 100px;
    // background: url('../../assets/images/bgimg.png');

    width: 100%;
    margin: 0 auto;
    height: 100vh;
    background: #fff;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // box-sizing: border-box;
    // .canvasImage {
    //     width: 311px;
    //     img {
    //         width: 100%;
    //         border-radius: 8px;
    //         overflow: hidden;
    //     }
    //     .tips {
    //         width: 100%;
    //         text-align: center;
    //         font-size: 0.2rem;
    //         color: #999;
    //         margin-top: 0.2rem;
    //     }
    // }
    .wrap {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
    .printCanvas {
        width: 100%;
        height: 482px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .tips {
        width: 100%;
        text-align: center;
        font-size: 0.2rem;
        color: #999;
        margin-top: 0.2rem;
    }

    // .channel-conetnt {
    //     width: 311px;
    //     height: 311px;
    //     background: #fff;
    //     border-radius: 4px;
    //     box-shadow: 0px 0px 9.460076332092285px 0px rgba(0, 0, 0, 0.16);
    //     margin: 0 auto;
    //     img {
    //         padding: 32px;
    //         width: 247px;
    //         height: 247px;
    //         object-fit: contain;
    //     }
    //     &__tip {
    //         font-size: 12px;
    //         font-weight: 400;
    //         font-family: 'PingFang SC';
    //         line-height: 18px;
    //         display: flex;
    //         justify-content: center;
    //         margin-top: 20px;
    //         color: #fff;
    //         .fingerprint {
    //             margin-right: 5px;
    //         }
    //     }
    // }
    .xiaoE-footer {
        position: absolute;
        bottom: 0.5rem;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .warp-error {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            width: 2rem;
        }
        p {
            margin-top: 10px;
        }
    }
}
</style>
